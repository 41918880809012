.invalid-feedback {
    font-size: 12px;
    margin-bottom: -1rem;
    line-height: 2;

    .form-error-icon {
        font-size: 11px;
        background: red;
        color: white;
        border-radius: 1rem;
        padding: 3px 7px;
    }
}

.is-invalid {
    border-color: #f90033;
}