.member-section {
    position: relative;
    background-color: #fff;
    padding: 6rem 0;
    z-index: 1;

    .white {
        color: $primary;
        &:after {
            background-color: white;
        }
    }

    &::before {
        content: "";
        background-color: $primary;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 52.8rem;
        z-index: -1;
    }

    &__facts {
        text-align: center;
        display: flex;
        flex-direction: column;
        color: #fff;
    }

    &__fact {
        padding: 1rem 0;
    }

    &__fact-heading {
        font-size: 1.6rem;
        line-height: 3rem;
        font-weight: 600;
    }

    &__fact-num {
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 5.2rem;
    }

    @include media-breakpoint-up(md) {
        padding: 8rem 0;

        // &::before {
        //     he
        // }

        &__facts {
            justify-content: space-around;
            flex-direction: row;
            text-align: left;
        }

        &__fact-num {
            font-size: 4.8rem;
        }
    }
}

.member-card {
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(236, 38, 88, 0.07);
    padding: 3rem;

    &__desc {
        color: #af0735;
        line-height: 3rem;
        font-size: 2rem;
        font-weight: bold;
    }

    &__list {
        font-family: "Hind", sans-serif;
        padding-left: 2.5rem;
        line-height: 2.4rem;

        li {
            position: relative;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }

            &::before {
                content: "";
                position: absolute;
                left: -2.5rem;
                top: 50%;
                transform: translateY(-50%);
                background-color: $primary;
                border-radius: 5rem;
                width: 1rem;
                height: 1rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 8rem 5rem 4rem;
    }
}

.partners {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > *:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    img {
        max-width: 14.4rem;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-around;
    }
}
