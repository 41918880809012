.page-header {
    padding: 120px 0;
    background-repeat: no-repeat;
    background-position: center;

    &.special {
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;

        .background-image-wrapper {
            top: 0;
            position: absolute;
            min-width: 100%;
            img {
                min-width: 100%;
            }
        }

        .spacer {
            height: calc(((576px - (576px - 100vw)) / 576) * 270);
            @include media-breakpoint-up(sm) {
                height: calc(((768px - (768px - 100vw)) / 768) * 270);
            }
            @include media-breakpoint-up(md) {
                height: calc(((992px - (992px - 100vw)) / 992) * 270);
            }
            @include media-breakpoint-up(lg) {
                height: calc(((1200px - (1200px - 100vw)) / 1200) * 270);
            }
            @include media-breakpoint-up(xl) {
                height: calc(((1400px - (1400px - 100vw)) / 1400) * 270);
            }
            @media (min-width: 1400px) {
                height: calc(((1600px - (1600px - 100vw)) / 1600) * 270);
            }
            @media (min-width: 1600px) {
                height: calc(((1800px - (1800px - 100vw)) / 1800) * 270);
            }
            @media (min-width: 1800px) {
                height: calc(((2000px - (2000px - 100vw)) / 2000) * 270);
                max-height: 270px;
            }
        }

    }
    .min-width{
        min-width: 200px;
    }
}

.page-heading {
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
}
