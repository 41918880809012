.button {
    color: #fff;
    border: none;
    outline: none;
    padding: 1.3rem 3rem;
    text-decoration: none;
    background-color: $primary;
    border-radius: 5rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px rgba($primary, 0.2);
    position: relative;
    transition: color 0.3s ease;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    backface-visibility: hidden;
    cursor: pointer;
    @include media-breakpoint-up(md) {
        padding: 1.3rem 4.5rem;
    }

    &:hover {
        color: $primary;

        &::before {
            transform: translateX(0);
        }

        &::after {
            transform: translateX(100%);
        }
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: calc(100% + 4px);
        backface-visibility: hidden;

        border-radius: 5rem;
        transition: transform 0.3s ease;
        z-index: -1;
    }

    &::before {
        background-color: #fff;
        transform: translateX(-100%);
    }

    &::after {
        background-color: $primary;
    }

    &--sm {
        padding: 1rem 1.5rem;
    }

    &--lg {
        padding: 1.3rem 8rem;
    }

    &--light {
        color: $primary;
        background-color: #ccc;

        &:hover {
            color: #fff;
        }

        &::before {
            background-color: $primary;
        }

        &::after {
            background-color: #fff;
        }
    }

    &--to-light {
        background-color: $primary;
        transition: background-color 0.3s ease;

        &:hover {
            color: $primary;
            background: #fff;
        }

        &::after,
        &::before {
            content: none;
        }
    }

    &--primary {
        background-color: $primary;

        &:hover {
            background-color: $primary;
            color: #fff;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    &--reverse {
        &::before {
            transform: translateX(0) !important;
        }

        &::after {
            transform: translateX(100%) !important;
        }

        &:hover::before {
            transform: translateX(-100%) !important;
        }

        &:hover::after {
            transform: translateX(0) !important;
        }
    }
}
