// ABSTRACT
@import "abstract/variables";
@import "abstract/classes";

// VENDORS
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";

// BASE
@import "base/minireset";
@import "base/base";
@import "base/typography";

// COMPONENTS
@import "components/buttons";
@import "components/cards";
@import "components/cluster";
@import "components/highlight";
@import "components/contact-card";
@import "components/cart-summary";
@import "components/accordion";

// LAYOUTS
@import "layouts/navbar";
@import "layouts/header";
@import "layouts/section-cluster";
@import "layouts/section-news";
@import "layouts/section-testimonial";
@import "layouts/section-event";
@import "layouts/section-member";
@import "layouts/newsletter";
@import "layouts/footer";
@import "layouts/breadcrumb";
@import "layouts/contact-form";
@import "layouts/page-header";
@import "layouts/areabricks";
@import "layouts/slide-cards";
@import "layouts/form-errors";
@import "layouts/profile";
@import "layouts/settings";
@import "layouts/filters";

// PAGES
@import "pages/home";
@import "pages/events";

body {
    background-color: white;
    overflow: hidden;
}

