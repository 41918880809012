.settings {
    .list-group {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;

        &-item {
            border: none;

            .fa {
                color: $primary;
            }
        }
    }
}