html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  min-width: 320px;
  overflow-x: hidden;
  background-color: #fff;
  touch-action: manipulation;
}

img {
  vertical-align: middle;
}

a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: inline-block;
  text-decoration: none;
}

button,
input,
textarea {
  font-family: inherit;
}

.eventpage__description ol,
.abtextblock ol {
  margin-left: 17px;
}

.eventpage__description ul,
.abtextblock ul {
  margin-left: 14px;
  list-style-type: disc;
}
