.o {
    opacity:0;
}

.abs{
    position:absolute;
    top:0px;
    left:0px;
}

.rel {
    position:relative;
    left:0px;
}

.fix {
    position:fixed;
}

.cursor {
    cursor:pointer;
}


.none {
    display:none;
}

#ctn {
    margin: 10px auto;
    overflow: hidden;
    display: block;
}

$clusterWidthLg : 1000px;
$clusterWidthMd : 500px;

#ctn {
    width: $clusterWidthLg;
    height: $clusterWidthLg;
    img, svg {
        width: $clusterWidthLg;
        height: $clusterWidthLg;
        .ico defs rect {
            width: $clusterWidthLg / 10 * 1.6;
            height: $clusterWidthLg / 10 * 1.6;
        }
    }
}

@include media-breakpoint-down(lg){
    div#ctn.rel.o {
        display: none;
    }
}



//@include media-breakpoint-up(md) {
//    #ctn {
//        width: $clusterWidthMd;
//        height: $clusterWidthMd;
//        img, svg {
//            width: $clusterWidthMd;
//            height: $clusterWidthMd;
//            .ico defs rect {
//                width: $clusterWidthMd / 10 * 1.6;
//                height: $clusterWidthMd / 10 * 1.6;
//            }
//        }
//    }
//}


.st0e{fill:#AF0735;}
.st1e{opacity:0.5;}
.st2e{clip-path:url(#SVGID_2e_);}
.st3e{clip-path:url(#SVGID_4e_);fill:url(#SVGID_5e_);}
.st4e{clip-path:url(#SVGID_7e_);fill:#AF0735;}
.st5e{fill:#FFFFFF;}

.st0f{fill:#AF0735;}
.st1f{opacity:0.5;}
.st2f{clip-path:url(#SVGID_2f_);}
.st3f{clip-path:url(#SVGID_4f_);fill:url(#SVGID_5f_);}
.st4f{clip-path:url(#SVGID_7f_);fill:#AF0735;}
.st5f{fill:#FFFFFF;}

.stt0{fill:#AF0735;}
.stt1{opacity:0.5;}
.stt2{clip-path:url(#SVGIDA_2_);}
.stt3{clip-path:url(#SVGIDA_4_);fill:url(#SVGIDA_5_);}
.stt4{clip-path:url(#SVGIDA_7_);fill:#AF0735;}
.stt5{fill:#FFFFFF;}

.stu0{fill:#AF0735;}
.stu1{opacity:0.5;}
.stu2{clip-path:url(#SVGIDB_2_);}
.stu3{clip-path:url(#SVGIDB_4_);fill:url(#SVGIDB_5_);}
.stu4{clip-path:url(#SVGIDB_7_);fill:#AF0735;}
.stu5{fill:#FFFFFF;}


.st0{clip-path:url(#SVGID_2_);fill:rgba(255,255,255,0);opacity:0;}
.st1{clip-path:url(#SVGID_2_);}
.st2{fill:#AF0735;}
.st3{clip-path:url(#SVGID_4_);fill:#FFFFFF;}
.st4{fill:#FFFFFF;}
.st5{fill:#5D5D5D;}

.st0b{clip-path:url(#SVGID_2_);fill:rgba(255,255,255,0);opacity:0;}
.st1b{clip-path:url(#SVGID_2_);}
.st2b{fill:#5D5D5D;}
.st3b{fill-rule:evenodd;clip-rule:evenodd;fill:#AF0735;stroke:#AF0735;stroke-width:0.5;stroke-miterlimit:10;}


.st0c{clip-path:url(#SVGID_2_);fill:rgba(255,255,255,0);opacity:0;}
.st1c{clip-path:url(#SVGID_2_);}
.st2c{fill:#5D5D5D;}
.st3c{fill:#AF0735;}
.st4c{fill:#FFFFFF;}


.st0d{clip-path:url(#SVGID_2_);fill:rgba(255,255,255,0);opacity:0;}
.st1d{clip-path:url(#SVGID_2_);}
.st2d{fill:#5D5D5D;}
.st3d{fill:#AF0735;}