.section-cluster {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #fff;

  &__heading {
    font-size: 3rem;
    line-height: 4.8rem;
    font-weight: 700;
  }

  &__subheading {
    color: $primary;
    font-weight: 700;
    line-height: 1.9rem;
    margin-top: 2.5rem;
  }

  p {
    margin-top: 3rem;
    font-family: "Hind", sans-serif;
    line-height: 3rem;
  }

  &__play {
    margin-top: 6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__play-button {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 5rem;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-right: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $primary;
      animation: pulse-border 1500ms ease-out infinite;
      border-radius: 50%;
      z-index: -1;
    }

    @keyframes pulse-border {
      100% {
        opacity: 0;
        transform: scale(1.4);
      }
    }
  }

  &__play-heading {
    font-weight: 700;
  }

  &__play-subheading {
    margin: 0 !important;
    font-size: 1.4rem;
    color: $primary;
    font-weight: 500;
  }

  &__img {
    transition: transform 0.3s ease;
    margin-top: 3rem;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  &--active &__modal-wrapper {
    visibility: visible;
    opacity: 1;
  }

  &__modal-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &__modal-body {
    width: 80%;
    height: 80%;
  }

  #cluster-player {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;

    &__play {
      margin-top: 9rem;
    }
  }
}
