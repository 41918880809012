.header {
  width: 100%;
  height: 68rem;
  background-position: 10.65% 30.6%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease;

  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__body {
    transform: translateY(-5rem);
  }

  &__subheading {
    font-family: "Hind", sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 3.5rem;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  .heading-primary {
    display: inline-block;
  }

  .typed-wrapper {
    height: 5.2rem;
  }

  .typed-cursor {
    color: $primary;
  }

  &__button {
    margin-top: 5rem;
  }

  @include media-breakpoint-up(lg) {
    height: 69.4rem;
    background-position: 52.05% 10%;

    .typed-wrapper {
      height: 7.2rem;
    }
  }
}
