$redColor : #9F203F;
$whiteColor : #FFFFFF;
$iconSize : 60px;

.slidecard{
    position: relative;
    overflow: hidden;
    color: white;

    img {
        width: 100%;
    }

    &__title {
        font-size: 1.5em;
        margin-bottom: 10px;
        margin-top: -9px;
    }

    &__text {
        padding: 1rem;
    }

    &__textbox {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        transition: height 1s;

        img {
            width: 30px;
            height: 30px;
        }

        .tfz-icon-arrow {
            transition: transform 1s;
        }

        &--closed {
            height: 60px;
            .tfz-icon-arrow {
                transform: rotate(-0.25turn);
            }
            a {
                visibility: hidden;
                opacity: 0;
            }
        }
        &--open {
            height: 70%;
            .tfz-icon-arrow {
                transform: rotate(0.25turn);
            }

            .slidecard__text {
                overflow: auto;
            }
        }

        &--red {
            color: $whiteColor;
            background-color: $redColor;
        }

        &--white {
            color: $redColor;
            background-color: $whiteColor;
        }
    }

    &__iconbox {
        margin: auto;
        margin-top: -$iconSize / 2;
        width: $iconSize;
        height: $iconSize;
        border-radius: $iconSize / 2;
        display: flex;
        align-items: center;
        justify-content: center;
        &--red{
            background-color: $redColor;
        }
        &--white {
            background-color: $whiteColor;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            transition:  opacity 2s;
            position: absolute;
            bottom: 10px;
            border-radius: 30px;
            padding: 5px 20px;
        }

        &--red {
            a{
                color: $whiteColor;
                border: 2px solid $whiteColor;
            }
        }

        &--white {
            a{
                color: $redColor;
                border: 2px solid $redColor;
            }
        }
    }
}