// SERCICE CARD
.service-card {
    padding: 5.5rem 3rem 3rem;
    background-color: #fcfbfc;
    position: relative;
    z-index: 1;

    * {
        transition: color 0.3s ease;
    }

    p {
        margin-top: 2.5rem;
        font-family: "Hind", sans-serif;
        line-height: 2.8rem;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2rem;
        background-color: $primary;
        transition: height 0.3s ease;
        z-index: -1;
    }

    &:hover {
        * {
            color: #fff !important;
        }

        &::before {
            height: 100%;
        }
    }
}

// EVENT CARD
.event-card {
    text-decoration: none;
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(175, 7, 53, 0.07);
    border-radius: 0.3rem;
    overflow: hidden;
    text-align: left;

    .button {
        text-transform: uppercase;
        margin-top: 2rem;
        min-width: 150px;
        text-align: center;
        white-space: nowrap;

        i {
            padding-left: 5px;
        }
    }

    &__date {
        background-color: $primary;
        color: #fff;
        display: inline-block;
        line-height: 3rem;
        padding: 0 1rem;
        margin-bottom: 1rem;
    }

    &__heading {
        font-weight: 700;
        font-size: 1.8rem;
        color: $primary;
    }

    &__icons {
        img {
            margin-right: 0.5rem;
            width: 24px;
            height: 24px;
        }

        img:hover {
            opacity: 0.6;
        }

        @include media-breakpoint-down(md) {
            margin-top: 1rem;
        }
    }

    p {
        font-family: "Hind", sans-serif;
        line-height: 3rem;
    }

    &__body {
        padding: 2rem;
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;

        &__img {
            max-width: 30rem;
            flex-shrink: 0;
        }

        &__text-block {
            margin-top: 0;
            flex-grow: 1;
        }

        .button {
            flex-shrink: 0;
            margin-top: 1rem;
        }
    }

    @include media-breakpoint-up(lg) {
        &__img {
            max-width: 27.2rem;
        }

        &__body {
            display: flex;
            padding: 3rem;
        }

        .button {
            margin-top: 0;
        }
    }
}

// TESTIMONIAL CARD
.testimonial-card {
    background-color: #fff;
    border-radius: 0.3rem;
    padding: 3rem;

    &__body {
        margin-top: 2rem;
    }

    &__img {
        flex-shrink: 0;
    }

    &__img img {
        width: 7.6rem;
        height: 7.6rem;
        border-radius: 5rem;
    }

    &__stars {
        display: flex;
        color: $primary;
        font-size: 2rem;

        & > *:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__text {
        font-family: "Hind", sans-serif;
        margin-top: 2.5rem;
    }

    &__name {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 3.8rem;
        margin-top: 1.5rem;
    }

    &__position {
        margin-top: 0;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.3rem;
        margin-bottom: 1rem;
    }

    &__company {
        font-family: "Hind", sans-serif;
        color: $primary;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-start;
        padding: 4rem;

        &__body {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
}

// NEWS CARD
.news-swiper {
    padding-left: 15px;
    padding-right: 15px;
}

.news-card {
    background-color: #fff;
    text-align: center;

    &.card-shadow {
        box-shadow: 0px 15px 15px rgba(175, 7, 53, 0.12);
    }

    &:hover &__heading {
        color: $primary;
    }

    &:hover &__footer {
        background-color: $primary;
    }

    &:hover .button {
        color: #fff;
        background-color: $primary;

        &::before {
            background-color: $primary;
        }

        &::after {
            background-color: #fff;
        }
    }

    .button:hover {
        color: $primary;
        background-color: #fff;
    }

    &__header {
        position: relative;
    }

    &__body {
        padding: 3rem;
        border-bottom: 1px dashed #ededed;
    }

    &__footer {
        padding: 2rem 3rem;
    }

    &__tag {
        color: #fff;
        position: absolute;
        top: 2rem;
        left: 2rem;
        text-transform: uppercase;
        background-color: $primary;
        display: inline-block;
        font-size: 1.2rem;
        letter-spacing: 0.3rem;
        padding: 0.4rem 1rem;
        border-radius: 0.4rem;
    }

    &__heading {
        transition: color 0.3s ease;
    }

    &__footer {
        transition: background-color 0.3s ease;
    }

    &__desc {
        font-family: "Hind", sans-serif;
        line-height: 2.8rem;
        margin-top: 1rem;
    }

    .button {
        border: 2px solid #fff;
        font-size: 1.2rem;
        padding: 0.6rem 2.5rem;
        color: $primary;
        transition: background-color 0.4s ease;

        &::before {
            background-color: #fff;
        }

        &::after {
            background-color: #fff;
        }
    }

    img {
        max-height: 20.5rem;
        width: 100%;
        object-fit: cover;
    }

    @include media-breakpoint-down(sm) {
        img {
            max-height: 41rem;
        }
    }

    @include media-breakpoint-up(lg) {
        &__tag {
            top: 3rem;
            left: 3rem;
        }
    }
}
