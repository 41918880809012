.section-news {
  //background-color: #414141;
  padding: 4rem 0 6rem;

  .swiper-container {
    padding-bottom: 5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 4rem 0 8rem;

    .swiper-container {
      padding-bottom: 0;
    }
  }

  &__heading {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-image: url(../../images/circle-line.png);
      background-repeat: no-repeat;
      background-position: top center;
      width: 100%;
      height: 7rem;
      left: 0;
      top: 60%;
      transform: translateY(-20%);
      opacity: 0.7;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $primary;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary;
    border-radius: 5rem;
    transition: all 0.3s ease;
    top: 45%;

    &:hover {
      background-color: $primary;
      color: #fff;
    }

    &hove &::before,
    &::after {
      content: none;
    }
  }

  .swiper-button-prev {
    left: -5rem;

    & > * {
      transform: translateX(-0.1rem);
    }
  }

  .swiper-button-next {
    right: -5rem;

    & > * {
      transform: translateX(0.1rem);
    }
  }

  .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
    background-color: #222222;
    margin: 0 1rem !important;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: $primary;
  }
}
