.service-container {
  margin-top: -8.5rem;
}

.services-row {
  margin: -1.5rem;
  & > div {
    padding: 1.5rem;
  }

  .service-card {
    height: 100%;
  }
}
