input:disabled {
    opacity: 0.7;
}

.error-wrapper, .contact-form-card {
    box-shadow: 0px 0px 6px rgb(177 177 177 / 23%);

    .alert {
        padding: 10px;
        font-size: 16px;
        line-height: 30px;
        border-radius: 5px;

        &-success {
            border: 2px solid #07af81;
        }

        &-danger {
            border: 2px solid $primary;
        }
    }
}

.error-wrapper {
    padding: 25px;
    margin-bottom: 30px;

    &.half {
        .alert {
            @include media-breakpoint-up(md) {
                width: calc(50% - 40px);
            }
        }
    }
}

.contact-form-card {
    padding: 50px 45px 52px 45px;

    .tooltip {
        position: relative;
        height: 100%;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 25px;
        color: $primary;

        &#cluster-tooltip {
            @include media-breakpoint-up(md) {
                position: absolute;
                left: 148px;
                top: 2px;
            }
            margin-bottom: 15px;
            height: auto;
            background-color: white;
        }

        &#kompetenzen-tooltip {
            @include media-breakpoint-up(md) {
                position: absolute;
                left: 170px;
                top: 2px;
            }
            margin-bottom: 15px;
            height: auto;
            background-color: white;
        }

        &:hover .tooltiptext {
            visibility: visible;
        }

        .tooltiptext {
            visibility: hidden;
            width: 200px;
            background-color: $primary;
            color: #fff;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            font-size: 1.1rem;
            font-family: "Poppins", Helvetica, sans-serif;
            font-weight: normal;
            padding: 5px;
            margin-left: 1rem;

        }
    }

    &__subtitle {
        color: #62809a;
        margin: 15px 0px 30px 0px;
        font-family: "Hind", sans-serif;
        font-size: 16px;
        line-height: 30px;
    }


    .alert {
        margin-bottom: 30px;
    }
}

.contact-form {
    .choice-type {
        position: relative;

        label {
            color: $primary;
        }

        fieldset {
            border: 1px solid #f6c0cb;
            padding-left: 10px;
            padding-bottom: 10px;
            margin-bottom: 3rem;

            legend {
                color: $primary;
            }

            .contact-form__choice {
                display: flex;

                .form-check {
                    margin-right: 4rem;
                }
            }
        }
    }

    &__group {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }

        div {
            width: 100%;
        }

        &.half {
            div {
                margin-bottom: 15px;
                @include media-breakpoint-up(sm) {
                    width: calc(50% - 15px);
                    margin-bottom: 0;
                }
            }

            .form-group {
                margin-right: 1rem;
            }
        }
    }

    &__input {
        width: 100%;
        border: 1px solid #f6c0cb;
        transition: border 0.3s ease;
        outline: none !important;
        display: block;
        height: 50px;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 1.5;
        background-color: #fff;
        border-radius: 0.25rem;
        backface-visibility: hidden;

        &::placeholder {
            color: $primary;
        }

        &:focus {
            border-color: $primary;
        }
    }

    textarea.contact-form__input {
        height: 120px;
    }

    @include media-breakpoint-up(lg) {
        &__group {
            display: flex;

            & > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 30px;
            }
        }
    }
}

#newsletterActive {
    margin-left: 1rem;
}

.grecaptcha-badge {
    visibility: hidden;
}
