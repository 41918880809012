.newsletter {
  padding: 2.4rem 0;
  background-color: $primary;
  color: #fff;

  &__heading {
    font-size: 2.2rem;
    font-weight: 600;
  }

  &__form {
    margin-top: 1.5rem;
    display: flex;

    input {
      flex: 1;
      margin-right: 2rem;
      background-color: $primary;
      height: 5rem;
      border: 1px solid #d4435a;
      padding: 1rem 1.5rem;

      &,
      &::placeholder {
        color: #fff;
        font-family: "Hind", sans-serif;
        font-size: 1.4rem;
      }
    }

    button[type="submit"] {
      background: #e03450;
      font-size: 2rem;
      cursor: pointer;
      border: 1px solid #d4435a;
      padding: 5px 15px;
      height: 5rem;
      color: #fff;
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__form {
      margin-top: 0;
      margin-left: 4rem;
    }

    input {
      width: 41rem;
    }
  }
}
