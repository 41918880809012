.abtextblock {
    ul {
        list-style: disc;
        padding-left: 30px;
    }

    h2 {
        font-size: 1.8em;
        padding-bottom: 0.5em;
    }

    h3 {
        font-size: 1.6em;
        padding-bottom: 0.4em;
    }

    h4 {
        font-size: 1.4em;
        padding-bottom: 0.3em;
    }

    h5 {
        font-size: 1.2em;
        padding-bottom: 0.2em;
    }

    a {
        color: $primary;
        text-decoration: underline;
    }
}