.section-event {
    padding: 6rem 0;

    &__cards {
        margin-top: 6rem;

        & > *:not(:first-child) {
            margin-top: 1.5rem;
        }

        .categories {
            img {
                width: auto;
                height: 40px;
            }
        }

        .event-card__body {
            button {
                margin-left: 0;
                min-width: 150px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 8rem 0;
    }
}
