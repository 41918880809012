.checkout {
    .button {
        width: 100px;
        @include media-breakpoint-up(md) {
            width: 125px;
        }
    }

    .contact-form-card {
        @include media-breakpoint-down(md) {
            padding: 25px;
        }
    }

    .cart-summary {
        overflow: auto;

        thead, tbody, tfoot {
            > tr > td {
                font-size: 12px !important;
                @include media-breakpoint-up(md) {
                    font-size: 14px !important;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 16px !important;
                }
            }
        }

        .table th, .table td {
            padding: .5rem;
            @include media-breakpoint-up(lg) {
                padding: .75rem;
            }
        }
    }
}
