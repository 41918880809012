.footer {
  background-color: #101317;
  color: #94969f;
  font-size: 1.4rem;

  .row > *:not(:first-child) {
    margin-top: 4rem;
  }

  // links
  a {
    text-decoration: none;
    transition: color 0.3s ease;
    &:hover {
      color: $primary !important;
    }
  }

  // main footer
  &__main {
    padding: 6rem 0;
  }

  &__heading {
    color: #fff;
    font-weight: 700;
    margin-bottom: 3.5rem;
  }

  // footer list (nav)
  &__nav {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex-basis: 50%;
    }

    a {
      padding: 0.7rem 0;
    }
  }

  // footer contact
  &__contact {
    li {
      margin-top: 3rem;
    }

    span {
      padding: 0 1rem;
    }
  }

  // footer social
  &__social {
    display: flex;
    margin-top: 3.5rem;

    li {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    a {
      width: 4rem;
      height: 4rem;
      border-radius: 5rem;
      background-color: #2d3138;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .row > *:not(:first-child) {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(md) {
    &__main {
      padding: 10rem 0;
    }

    &__heading {
      margin-bottom: 5.5rem;
    }
  }
}

.footer__bottom {
  padding: 2rem 0;
  border-top: 1px solid #31363c;
  font-size: 1.2rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    .container {
      display: flex;
      justify-content: space-between;
    }
  }
}

.footer__bottom-nav {
  text-transform: uppercase;
  display:flex;
  margin-top: 2rem;

  li:not(:last-child) {
      margin-right: 1rem;
      @include media-breakpoint-up(md) {
          margin-right: 12rem;
      }
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
