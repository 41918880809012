/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    a {
        color: $primary;
        text-decoration: underline;
    }

    ul {
        list-style: inherit;
        padding: inherit;

        li {
            margin: inherit;
        }
    }

    .accordion-btn {
        background-color: white;
        color: $primary;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        font-size: 20px;
        font-weight: bold;
        position: relative;

        &:not(:first-child) {
            span {
                position: absolute;
                left: 0;
                top: -2px;
                width: 100%;
                background-image: linear-gradient(to right, #8080803b 33%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 8px 2px;
                background-repeat: repeat-x;
                height: 100%;
            }
        }


        &:hover {
            span {
                background-image: none;
            }

            color: white;
            background-color: $primary;

            &:after {
                content: url("/images/icons/arrow.svg");
            }

        }

        &:after {
            background-image: none;
            content: url(/images/icons/arrow-red.svg);
            width: 20px;
            position: absolute;
            height: 20px;
            margin-left: 5px;
            transform: rotate(-90deg);
            margin-top: 5px;
            margin-right: 18px;
            right: 0;
            transition: transform .5s ease-out;
        }

        &.active {
            span {
                background-image: none;
            }

            background-color: $primary;
            color: white;

            &:after {
                content: url("/images/icons/arrow.svg");
                transform: rotate(90deg);
            }
        }
    }

    .panel {
        padding: 0 3rem;
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out;

        .outer-border {
            background-color: $primary;
            padding: 2px;

            .accordion-btn:not(:first-child) {
                span {
                    height: 2px;
                    background-color: $primary;
                }
            }
        }
    }

}