.eventpage {
    &-card{
        padding: 35px 25px;
        background: $primary;
        color: #fff;
        display: flex;
        align-items: flex-start;

        &__icon {
            width: 50px;
            margin-right: 20px;
            flex-shrink: 0;
        }

        &__heading {
            line-height: 16px;
        }

        &__list {
            line-height: 26px;
            font-weight: 700;
            margin-top: 15px;
            font-size: 15px;
        }
    }

    &-cards > * + * {
        margin-top: 40px;
    }


    &__table {
        width: 100%;

        td {
            vertical-align: top;
        }
    }

    &__button {
        &--wrapper {
            width: 100%;
        }
    }

    padding-bottom: 5rem;

    &-product {
        display: flex;
        background-color: #ebebeb;
        margin-top: 2rem;
        align-items: center;
        justify-content: space-between;
        padding: 25px 15px;

        h4 {
            font-weight: bold;
        }

        .cart-button .input-group {
            display: flex;

            #coreshop_add_to_cart_cartItem_quantity {
                width: 45px;
                padding: 5px;
                border-color: #bbb9b9;
                border-radius: 0.5rem;
                border-style: solid;
                margin-right: 2rem;
            }

            .input-group-append button {
                border-color: #bbb9b9;
                border-radius: 0.5rem;
                border-style: solid;
                padding: 5px 10px;
                color: #6d6d6d;
                background-color: white;

                .fa-shopping-cart {
                    display: none;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

