.contact-card {
  padding: 35px 25px;
  background: $primary;
  color: #fff;
  display: flex;
  align-items: flex-start;

  &__icon {
    width: 50px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__heading {
    line-height: 16px;
  }

  &__list {
    line-height: 26px;
    font-weight: 700;
    margin-top: 15px;
    font-size: 15px;
  }
}

.contact-cards > * + * {
  margin-top: 40px;
}
