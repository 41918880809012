.section-testimonial {
    padding: 6rem 0;
    background-color: #f0f4f6;

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .container {
        position: relative;
    }

    .swiper-container {
        padding-bottom: 5rem;
    }

    @include media-breakpoint-up(md) {
        padding: 8rem 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: $primary;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $primary;
        border-radius: 5rem;
        transition: all 0.3s ease;
        top: 45%;

        &:hover {
            background-color: $primary;
            color: #fff;
        }

        &hove &::before,
        &::after {
            content: none;
        }
    }

    .swiper-button-prev {
        left: -5rem;

        & > * {
            transform: translateX(-0.1rem);
        }
    }

    .swiper-button-next {
        right: -5rem;

        & > * {
            transform: translateX(0.1rem);
        }
    }

    .swiper-pagination-bullet {
        width: 1rem;
        height: 1rem;
        background-color: $primary;
        margin: 0 1rem !important;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 1.4rem;
            height: 1.4rem;
            top: -0.2rem;
            left: -0.2rem;
            border: 2px solid rgba(175, 7, 53, 0.6);
            border-radius: 5rem;
        }
    }
}
