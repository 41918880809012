.filters {
    flex-wrap: wrap;
    display: flex;

    li {
        border: 2px solid #e9ecef;
        cursor: pointer;
        border-radius: 50rem !important;
        padding: 1rem 2rem 1rem 1.4rem;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        margin-right: 1rem;

        @include media-breakpoint-up(md) {
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            border: none;
            padding: 0;
        }

        &.active-filter {
            text-decoration: underline;
            text-decoration-color: $primary;
            text-decoration-thickness: 2px;
        }
    }
}