.breadcrumb-wrapper {
  height: 70px;
  border-bottom: 1px solid #ececec;
}

.breadcrumb {
  display: flex;
  align-items: center;
  height: 70px;

  &__item:not(:last-child)::after {
    content: ".";
    padding: 0 6px 0 4px;
  }

  &__link {
    font-size: 12px;
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: $primary;
    }

    &--active {
      color: $primary;
    }
  }
}
