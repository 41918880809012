.navbar-wrapper {
    position: relative;
    z-index: 10;
}

.navbar {
    background-color: #fff;
    text-align: center;
    padding: 4rem 0;
    position: relative;

    & .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }
    }

    &__burger {
        position: relative;
        width: 2.5rem;
        height: 1.8rem;
        cursor: pointer;

        span {
            width: 2.5rem;
            height: 0.2rem;
            background-color: $primary;
            position: absolute;
            left: 0;
            transition: all 0.3s ease;
            transform-origin: 95%;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 0.8rem;
            }

            &:nth-child(3) {
                top: 1.6rem;
            }
        }
    }

    &--active &__burger {
        span {
            &:nth-child(1) {
                transform: rotate(-45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(45deg);
            }
        }
    }

    &__menu {
        transition: transform 0.3s ease;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 3rem;
        background-color: #fff;
        transform: translateY(-100%);
        z-index: -1;

        &--right {
            margin: auto;
            margin-right: 0;
        }
    }

    &--active &__menu {
        transform: translateY(0);
    }

    &__menu-item {
        border-bottom: 1px solid rgba(120, 138, 156, 0.2);
        font-weight: 600;

        &--active {
            color: $primary;
        }

        a {
            text-decoration: none;
        }

        & > a {
            padding: 1.6rem 0;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0.2rem;
                background-color: $primary;
                width: 0;
                transition: width 0.3s ease;
            }
        }
    }

    &__menu-link {
        display: block;
    }

    &__menu-link:hover {
        color: $primary;
    }

    @include media-breakpoint-up(lg) {
        padding: initial;
        height: 8rem;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }

        &__burger {
            display: none;
        }

        &__menu {
            max-height: initial;
            position: initial;
            transition: max-height 0.3s ease;
            padding: 0 !important;
            display: flex;
            height: 100%;
            transform: translateY(0);
            z-index: 1;
        }

        &__menu-item {
            border: none !important;
            padding: 0 1.8rem;

            &--active a::after {
                width: 100% !important;
            }

            &:hover > a::after {
                width: 100%;
            }

            a {
                padding: 0;
                transition: color 0.3s ease;
                height: 100%;
                display: flex;
                align-items: center;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

// NAVBAR DROPDOWN
.dropdown__trigger {
    cursor: pointer;
    user-select: none;
    display: block;
    position: relative;
}

@include media-breakpoint-down(md) {
    .navbar {
        border-bottom: 1px solid rgba(120, 138, 156, 0.2);
    }
    .navbar__menu {
        height: calc(100vh - 127px);
        overflow: auto;
    }

    .dropdown {
        position: relative;

        span {
            position: absolute;
            width: 25%;
            height: 100%;
            top: 0;
            right: 0;
        }

        &__menu {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease;
        }

        &__trigger {
            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 8px !important;
                height: 2px !important;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                left: initial !important;
                display: block;
                background: #af0735;
            }

            &::after {
                transform: translateY(-50%) rotate(90deg);
            }
        }

        &--active > &__trigger {
            &::after {
                display: none;
            }
        }

        &__menu-link {
            color: #222222;
            border-top: 1px solid rgba(120, 138, 156, 0.2);
            font-weight: 400;
            display: block;
            font-size: 14px;
            padding: 16px 20px !important;

            transition: background-color 0.3s ease;

            &:hover {
                background-color: $primary;
                color: #fff;
            }
        }
    }
}

.dropdown__trigger > ion-icon {
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}


.dropdown {
    position: relative;

    ion-icon {
        padding-left: 6px;
        font-size: 12px;
    }

    &:hover > &__trigger {
        &::after {
            transform: translateY(-120%) rotate(90deg);
            opacity: 0;
        }
    }

    &__trigger {
        position: relative;

        &:hover {
            &::before,
            &::after {
                background-color: #fff;
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 8px !important;
            height: 2px !important;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            left: initial !important;
            display: block;
            background: #af0735;
            transition: all 0.3s ease, opacity 0.3s 0.2s;
        }

        &::after {
            transform: translateY(-50%) rotate(90deg);
        }
    }

    &__menu {
        left: 100%;
        top: 0;
        position: unset;
        visibility: hidden;
        opacity: 0;
        background-color: #fff;
        transform: translateX(10px);
        transition: all 0.3s ease;
        backface-visibility: hidden;
    }

    &:hover > &__menu {
        @include media-breakpoint-down(md) {
            max-height: none;
        }
        visibility: visible;
        opacity: 1;
        transform: translateX(0) !important;
    }

    &__menu-item:last-child &__menu-link {
        border: none !important;
    }

    &__menu-item.login-class {
        a {
            display: block;
            text-align: left;
        }

        &.locked a:after {
            content: ' ';
            background: url(../../images/icons/lock-solid.svg) no-repeat;
            float: right;
            height: 20px;
            width: 20px;
            margin-top: 4px;
        }

        &.unlocked a:after {
            content: ' ';
            background: url(../../images/icons/lock-open-solid.svg) no-repeat;
            float: right;
            height: 20px;
            width: 24px;
            margin-top: 4px;
        }
    }

    &__menu-link {
        font-weight: 600;
        font-size: 14px;
        color: #222222;
        border-bottom: 1px solid rgba(120, 138, 156, 0.2);
        font-weight: 400;
        padding: 16px 20px !important;
        display: block;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $primary;
            color: #fff !important;
        }
    }
}



@include media-breakpoint-up(lg) {
    .navbar__menu-item.dropdown {
        & > .dropdown__menu {
            left: 18px !important;
            top: 100%;
            text-align: left;
        }

        & > .dropdown__trigger {
            &::after,
            &::before {
                content: none !important;
            }
        }
    }

    .dropdown {
        position: relative;

        ion-icon {
            padding-left: 6px;
            font-size: 12px;
        }

        &:hover > &__trigger {
            &::after {
                transform: translateY(-120%) rotate(90deg);
                opacity: 0;
            }
        }

        &__trigger {
            position: relative;

            &:hover {
                &::before,
                &::after {
                    background-color: #fff;
                }
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 8px !important;
                height: 2px !important;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                left: initial !important;
                display: block;
                background: #af0735;
                transition: all 0.3s ease, opacity 0.3s 0.2s;
            }

            &::after {
                transform: translateY(-50%) rotate(90deg);
            }
        }

        &__menu {
            left: 100%;
            top: 0;
            position: absolute;
            visibility: hidden;
            opacity: 0;
            width: 210px;
            background-color: #fff;
            box-shadow: 0px 10px 20px rgb(215 215 215 / 40%);
            transform: translateX(10px);
            transition: all 0.3s ease;
            backface-visibility: hidden;
        }

        &:hover > &__menu {
            @include media-breakpoint-down(md) {
                max-height: none;
            }
            visibility: visible;
            opacity: 1;
            transform: translateX(0) !important;
        }

        &__menu-item:last-child &__menu-link {
            border: none !important;
        }

        &__menu-item.login-class {
            a {
                display: block;
                text-align: left;
            }

            &.locked a:after {
                content: ' ';
                background: url(../../images/icons/lock-solid.svg) no-repeat;
                float: right;
                height: 20px;
                width: 20px;
                margin-top: 4px;
            }

            &.unlocked a:after {
                content: ' ';
                background: url(../../images/icons/lock-open-solid.svg) no-repeat;
                float: right;
                height: 20px;
                width: 24px;
                margin-top: 4px;
            }
        }

        &__menu-link {
            font-weight: 600;
            font-size: 14px;
            color: #222222;
            border-bottom: 1px solid rgba(120, 138, 156, 0.2);
            font-weight: 400;
            padding: 16px 20px !important;
            display: block;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: $primary;
                color: #fff !important;
            }
        }
    }

}

.navbar--fixed {
    @include media-breakpoint-down(md) {
        overflow: hidden !important;
    }
}

.navbar {
    &__menu-group {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        font-size: 20px;

        & > * {
            color: #222;

            &::after {
                content: none !important;
            }
        }

        & > *:not(:last-child) {
            margin-right: 10px;
        }
    }
}

i.fa-ticket-alt > span.cart-badge {
    margin-top: -10px;
    margin-left: 15px;
    display: block;
    background: #af0735;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: white;
    font-size: 14px;
}
