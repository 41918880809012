.highlight {
  background: #af0735;
  padding: 60px 30px;
  color: #fff;

  &__heading {
    font-size: 26px;
    font-weight: 700;
  }
    a{
        color: white;
    }
}
