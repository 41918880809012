@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

html {
  -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  color: #222;
  font-size: 1.6rem;
  line-height: 30px;
  font-family: "Poppins", Helvetica, sans-serif;
}

p {
  font-family: "Hind", sans-serif;
}

.text-500 {
  font-weight: 500;
}

.heading-primary,
.typed-cursor {
  font-size: 4.4rem;
  line-height: 5.2rem;
  font-weight: 700;

  @include media-breakpoint-up(lg) {
    font-size: 6rem;
    line-height: 7.2rem;
  }
}

.heading-secondary {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 54px;
}

.bottom-line {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -1.5rem;
    transform: translateX(-50%);
    width: 6rem;
    height: 0.2rem;
    background-color: $primary;
  }
}

.heading-tertiary {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
}

.subtitle {
  margin-top: 15px;
  color: $primary;
  font-size: 16px;
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.text-lg {
  font-size: 18px;
}
